import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable as __Observable } from 'rxjs';

const TOKEN_KEY = 'auth-token';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationCheckService {


  authenticationState: boolean = false;

  constructor() {
    this.checkToken();
  }

  async checkToken() {
    let token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      this.authenticationState = true;
    }
  }
  getAuthToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  async saveAuth(token) {
    localStorage.setItem(TOKEN_KEY, 'Bearer ' + token);
    this.authenticationState = true;
    return true;
  }

  logout() {
    localStorage.clear();
    this.authenticationState = false;
    return;
  }

  isAuthenticated() {
    return this.authenticationState;
  }

}
