import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationCheckService } from './authentication-check.service';
import { ScanningListComponent } from 'src/app/team-lead/scanning-list/scanning-list.component';
import { Observable,of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthenticationCheckService, private router: Router,) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authToken = this.auth.isAuthenticated();
    let pagePath = state.url;
    let userRole:any = localStorage.getItem("user_role");
    console.log('authToken:',authToken, pagePath, userRole);
    if (authToken == true) localStorage.setItem('login', 'true')
    if (authToken == false) localStorage.setItem('login', 'false')

    if (authToken) {
      // agent 
      if(userRole==7 && pagePath.indexOf("agent") == -1) {
        this.router.navigateByUrl('/agent', { replaceUrl: true });
      }
      if(userRole==1 && pagePath.indexOf("hubAdmin") == -1) {
        this.router.navigateByUrl('/hubAdmin', { replaceUrl: true });
      }
      if(userRole==14 && pagePath.indexOf("segment") == -1) {
        this.router.navigateByUrl('/segment', { replaceUrl: true });
      }
      if((userRole==15 || userRole==16) && pagePath.indexOf("approver") == -1) {
        this.router.navigateByUrl('/approver', { replaceUrl: true });
      }
      if(userRole==18 && pagePath.indexOf("team-lead") == -1) {
        this.router.navigateByUrl('/team-lead', { replaceUrl: true });
      }
      return true;
    } else if (authToken == false) {
      this.router.navigateByUrl('/signin', { replaceUrl: true });
    }
    return false;
  }
  canDeactivate(): Observable<boolean> {
    // Logic to determine if navigation back should be prevented
    // Example: return false to prevent navigation back
    // Your logic here
    return of(true); // or return an observable based on your logic
  }
}
