import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm;
  errorMessage: Boolean = true;
  message = "";
  userRole = 1
  isotpInput = true;
  submitOtpbtn = true;
  Getotpbtn = false
  mobileNo = '';
  mobilenoError = false;
  otpError = false;
  otp = '';
  userID = '';

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService,
    public location: Location,

  ) {
    this.checkLogin();
    this.loginForm = formBuilder.group({
      username: ['', Validators.compose([
        Validators.required
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])],
    });
    this.loginForm.valueChanges
      .subscribe(data => {

      });
  }

  ngOnInit() {
    // this.testApi();
  }



  login(formData) {
    if (this.loginForm.invalid) {
      this.errorMessage = false;
      if (formData.username == "" && formData.password == "") {
        this.message = "Please enter the username and password"
      } else if (formData.username == "") {
        this.message = "Please enter the username"

      } else {
        this.message = "Please enter the password"

      }
    } else {
      let apiUrl = this.apiConfig.login;
      let body = JSON.stringify({ "LoginForm": { "username": formData.username, "password": formData.password } });
      this.apiService.post(apiUrl, body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.errorMessage = true;
            let body = res.body;
            
            this.toast.Success('Login successful');
            localStorage.setItem("id", (res.body.data.id));
            localStorage.setItem("user_name", (res.body.data.user_name));
            localStorage.setItem("user_role", (res.body.data.user_role));
            this.auth.saveAuth(body.data.access_token);
            if (body.data.user_name == 'hubadmin' && body.data.user_role == 1) {
              this.router.navigate(["hubAdmin"]);
            }
            else if (body.data.user_name == 'agent' && body.data.user_role == 7) {
              this.router.navigate(["agent"]);
            }
            else if (body.data.user_role == 12) {
              // 12 - coupon supplier manager
              // localStorage.setItem("segment_id", (res.body.data.segment_id));
              // localStorage.setItem("segment_name", (res.body.data.segment_name));
              this.router.navigate(["coupon-supply"]);
            }
            else if (body.data.user_role == 13) {
              // 13 - supply chain
              localStorage.setItem("segment_id", (res.body.data.segment_id));
              localStorage.setItem("segment_name", (JSON.stringify(res.body.data.segment_name)));
              this.router.navigate(["supply-chain"]);
            }
            else if (body.data.user_role == 14) {
              localStorage.setItem("segment_id", (res.body.data.segment_id));
              localStorage.setItem("segment_name", (JSON.stringify(res.body.data.segment_name)));
              this.router.navigate(["segment"]);
            }
            else if (body.data.user_role == 15 || body.data.user_role == 16) {
              this.router.navigate(["approver"]);
            }
            else if (body.data.user_role == 8) {
            //   // this.router.navigate(["teamleader"]);
              this.router.navigate(["reporting-dashboard"]);
            }
            // else if (body.data.user_role == 9) {
            //   this.router.navigate(["branch-manager"]);

            // }

            else if (body.data.user_role == 10) {
              localStorage.setItem("segment_id", (res.body.data.segment_id));
              localStorage.setItem("segment_name", (res.body.data.segment_name));
              this.router.navigate(["factory-store"]);
            }

            else if (body.data.user_role == 11) {
              localStorage.setItem("segment_id", (res.body.data.segment_id));
              localStorage.setItem("segment_name", (res.body.data.segment_name));
              this.router.navigate(["cap-supplier"]);
            }
            else if (body.data.user_role == 17) {
              this.router.navigate(["scanner-agents"]);
            }

            else if (body.data.user_role == 18) {
              this.router.navigate(["team-lead"]);
            }
            
            else if (body.data.user_role == 19) {
              this.router.navigate(["call-center"]);
            }

            else {
              this.toast.Error('You dont have access to this module');
            }
          }
        }, err => {
          this.errorMessage = false;
          // console.log('err', err);
        })
    }

  }
  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }


  checkLogin() {
    let previousURL = localStorage.getItem("previousURL")
    let login = localStorage.getItem("login")
    let userRole: any = localStorage.getItem("user_role");
    //console.log("preee", previousURL);
    if (login == 'true') {
      if (previousURL) {
        this.router.navigateByUrl(previousURL)
      } else {
        if (userRole == 7) {
          this.router.navigateByUrl('/agent', { replaceUrl: true });
        } else if (userRole == 1) {
          this.router.navigateByUrl('/hubAdmin', { replaceUrl: true });
        } else if (userRole == 14) {
          this.router.navigateByUrl('/segment', { replaceUrl: true });
        } else if (userRole == 15 || userRole == 16) {
          this.router.navigateByUrl('/approver', { replaceUrl: true });
        } else if (userRole == 10) {
          this.router.navigateByUrl('/factory-store', { replaceUrl: true });
        } else if (userRole == 11) {
          this.router.navigateByUrl('/cap-supplier', { replaceUrl: true });
        } else if (userRole == 12) {
          this.router.navigateByUrl('/coupon-supply', { replaceUrl: true });
        } else if (userRole == 13) {
          this.router.navigateByUrl('/supply-chain', { replaceUrl: true });
        } else if (userRole == 17) {
          this.router.navigateByUrl('/scanner-agents', { replaceUrl: true });
        } else if (userRole == 18) {
          this.router.navigateByUrl('/team-lead', { replaceUrl: true });
        } else if (userRole == 19) {
          this.router.navigateByUrl('/call-center', { replaceUrl: true });
        }
      }
    }
  }

}


